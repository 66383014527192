import { LaunchScreen } from "@screens/Launch";

export async function getStaticProps() {
    return {
        props: {
            layout: "static",
        },
    };
}

export default function Launch() {
    return <LaunchScreen />;
}
